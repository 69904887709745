.session_info_wrapper .MuiOutlinedInput-notchedOutline {
  border-color: rgb(204, 204, 204) !important;
  border-width: 1px !important;
}
.session_info_wrapper .MuiInputBase-root {
  color: #707070 !important;
}
.session_info_wrapper .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #2b8dff !important;
}

.session_info_wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
