.header_main_wrapper {
  position: fixed;
  top: 0px;
  width: 100%;
  box-shadow: rgb(204, 204, 204) 0px 3px 10px;
  background-color: rgb(242, 242, 242);
  padding-left: 56px;
  padding-right: 56px;
  z-index: 20;
}

.header_extender_content_wrapper {
  min-height: 80px;
  height: 160px;
  max-height: 160px;
}

.header_extender {
  width: 30px;
  position: absolute;
  left: 50%;
  cursor: pointer;
  z-index: 11;
  transform: translate(-50%) rotate(180deg);
  transition: all 0.35s ease-in-out !important;
  bottom: -20px;
}

.header_extender.extended {
  transform: translate(-50%);
  bottom: 0px;
}

/*BUTTONS*/
.icon_button {
  width: 48px !important;
  height: 48px !important;
  cursor: pointer;
}

.icon_button:hover {
  background: #2b8dff !important;
  fill: #cccccc !important;
  transition: background-color 0.1s linear;
}

.download_button_option {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.notrealtime_message {
  font-family: 'Roboto';
  font-size: 19px;
  color: #707070;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
}

.download_button_option_box {
  cursor: pointer;
}

.download_button_option_box:hover {
  background-color: #cccccc;
}

.playbackrate_button:hover {
  background-color: #2b8dff;
  color: #cccccc !important;
  transition: background-color 0.1s linear;
}

.playbackrate_slider_container {
  width: 100%;
  background-color: white;
  display: flex;
  height: 25px;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  cursor: pointer;
}

.playbackrate_slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #cccccc;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  cursor: pointer;
}
.playbackrate_slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3px;
  height: 20px;
  background: #2b8dff;
  cursor: pointer;
}

.playbackrate_slider::-moz-range-thumb {
  width: 3px;
  height: 20px;
  background: #2b8dff;
  cursor: pointer;
}

/*BUTTONS*/

/*MUI*/
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-align-items-xs-center {
  flex-wrap: nowrap !important;
}

/*CONFIDENCE POPUP*/

.confidence_popup_title {
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 14px;
  /* color: #333333; */
  text-align: center;
  margin-bottom: 10px;
}
.confidence_popup_values_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confidence_popup_value {
  font-family: 'Roboto Mono';
  font-size: 14px;
  font-weight: 500;
  /* width: 48px; */
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
.confidence_popup_values_wrapper .confidence_popup_value:first-child {
  /* border-top: 2px solid #D7801B;
  border-bottom: 2px solid #D7801B; */
  color: #d7801b;
}
.confidence_popup_values_wrapper .confidence_popup_value:nth-child(2) {
  /* border-top: 2px solid rgba(215, 128, 27, 0.5);
  border-bottom: 2px solid rgba(215, 128, 27, 0.5); */
  color: rgba(215, 128, 27, 0.5);
}

.time_string {
  user-select: none !important;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: rgb(204, 204, 204);
  font-family: Roboto;
  font-weight: 700;
  white-space: nowrap;
  margin-top: 4px;
  margin-right: 30px;
}

@media (max-width: 660px) {
  .header_main_wrapper {
    padding-right: 25px;
    padding-left: 25px;
  }
  .icon_button {
    width: 36px !important;
    height: 36px !important;
  }
  .time_string {
    font-size: 0.9rem;
    margin-top: 2px;
    margin-right: 4px;
  }
  .header_extender_content_wrapper {
    height: 120px;
  }
}

@media (max-width: 560px) {
  .icon_button {
    /* width: 24px; */
    /* height: 24px; */
  }
  .time_string {
    font-size: 0.9rem;
    margin-top: 2px;
  }
}

@media (max-width: 480px) {
  .header_extender_content_wrapper {
    align-items: flex-start;
    padding-top: 32px;
  }
  .time_string {
    position: absolute;
    bottom: 20px;
    left: 36px;
  }
}
