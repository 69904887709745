.html,
body {
  overflow-y: hidden;
  line-height: 1.5;
}
button {
  border: none;
  outline: none;
  transition: transform 250ms ease-in-out;
  background-color: transparent;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none !important;
}
/*button:active {
  transform: scale(0.97);
  transition: transform 250ms ease-in-out;
}*/
.modal_overlay {
  position: fixed;
  padding: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000066;
  z-index: 100;
  /* opacity: 0; */
  /* pointer-events: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: all 0.2s ease-in-out; */
}
.modal_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  /* background-color: #f2f2f2; */
  /* padding: 30px; */
  margin: 0;
  border-radius: 1px;
}
.modal_buttons_wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.modal_message {
  margin: 0;
  margin-bottom: 30px;
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: normal;
}
.modal_button_aggre {
  text-transform: uppercase;
  background-color: #2b8dff;
  padding: 10px 12px;
  margin: 0;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
  border: none;
  outline: none;
  color: #f2f2f2;
  border-radius: 0;
  outline: none;
  transition: all 0.2s ease-in-out;
}
.modal_button_disaggre {
  color: #707070;
  border: none;
  background-color: transparent;
  font-family: 'Roboto';
  font-size: 14px;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-weight: 600;
  text-transform: uppercase;
  outline: none;
  transition: all 0.2s ease-in-out;
  margin-right: 15px;
}

.modal_button_aggre:hover {
  background-color: #107ffd;
  transition: all 0.2s ease-in-out;
}
.modal_button_disaggre:hover {
  color: #505050;
  transition: all 0.2s ease-in-out;
}

.MuiLinearProgress-colorSecondary {
  background-color: #c8e1ff51 !important;
}
.MuiLinearProgress-barColorSecondary {
  background-color: #2196f3 !important;
}

/* ANIMATED BUTTON START*/

/* ANIMATION 1 */

.button_holder {
  outline: none !important;
  background: none !important;
  border: none;
  position: relative;
  cursor: pointer;
  padding: 0;
  border-radius: 50%;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: padding 0.3s, margin 0.3s, background 0.3s;
}
.button_holder:disabled {
  cursor: not-allowed;
}

.mic_icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.outer_circle {
  width: 82px;
  height: 82px;
  border: 5px solid;
  border-radius: 50%;
  position: relative;
  box-sizing: border-box;
  will-change: border;
}
.outer_circle::after {
  content: '';
  width: 18px;
  height: 5px;
  border-radius: 10px;
  position: absolute;
  top: -8px;
  left: 50%;
  margin-left: -5px;
}

.outer_circle.animation_one {
  border-color: transparent !important;
  animation: circle-rotate 1s ease-in-out infinite;
  -webkit-animation: circle-rotate 1s ease-in-out infinite;
}
.outer_circle.outer_circle.animation_one::after {
  background: #f80534;
}
.outer_circle.animation_two {
  border-color: transparent !important;
  animation: circle-rotate 1s ease-in-out infinite;
  -webkit-animation: circle-rotate 1s ease-in-out infinite;
}
.outer_circle.outer_circle.animation_two::after {
  background: rgb(51, 51, 51);
  /* #333333 */
}
.outer_circle.recording {
  border-color: #f80534;
}

.outer_circle.recording {
  animation: border-pulsate-big 1.5s infinite;
  -webkit-animation: border-pulsate-big 1s ease-in-out infinite;
}

@keyframes circle-rotate {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes border-pulsate-big {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
    border-width: 15px;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes border-pulsate-big {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
    border-width: 15px;
  }
  100% {
    opacity: 1;
  }
}

@keyframes border-pulsate-med {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
    border-width: 12px;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes border-pulsate-med {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
    border-width: 12px;
  }
  100% {
    opacity: 1;
  }
}

@keyframes border-pulsate-low {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
    border-width: 10px;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes border-pulsate-low {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
    border-width: 10px;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 660px) {
  .outer_circle {
    width: 62px;
    height: 62px;
    border: 4px solid;
  }
  .outer_circle::after {
    width: 12px;
    height: 4px;
  }
  .mic_icon {
    height: 30px;
  }
}
