.warn_message {
    background-color: #DDB4E0;
    padding: 26px 52px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    border-radius: 4px;
}

.warn-snackbar-mail {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #2B8DFF;
}

p {
    margin: 0;
}