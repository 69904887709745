.search-wrapper {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  padding: 5px;
  white-space: nowrap;
  display: flex;
  padding-left: 30px;
  align-items: center;
  z-index: 16 !important;
  background-color: #f2f2f2;
  transition: all 200ms cubic-bezier(0.17, 0.67, 0.83, 0.67) !important;
  border-radius: 0;
}

/* ***** Icons */
.search-big-icon-wrapper {
  color: #757575;
  width: 62px;
  height: 62px;
  font-size: 48px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms cubic-bezier(0.17, 0.67, 0.83, 0.67) !important;
}

.search-big-icon-wrapper:hover {
  background-color: #75757527;
}

/* ***** Inner Input */
.search-wrapper-hidden {
  background-color: #e4e0e0;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.inner-input-wrapper {
  z-index: 17 !important;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 5px;
  margin-right: 100px;
  padding-bottom: 3px;
  margin-top: 4px;
  width: 100%;
  display: flex;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.425); */
}

.inner-input-wrapper > input {
  font-size: 22px;
  width: 100%;
  font-family: 'Roboto';
}

.input-delete-everything {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-delete-everything:hover {
  color: rgb(211, 65, 65);
}

.searchToken {
  border-radius: 2px;
  background-color: #d1d1d1;
  padding: 2px 6px;
  cursor: pointer;
  margin-left: 2px;
  font-size: 22px;
  font-family: 'Roboto';
  display: flex;
  align-items: center;
  height: 38px;
}

.searchToken:hover {
  background-color: #dadada;
}

.day-picker-wrapper {
  position: absolute;
  z-index: 22 !important;
  margin-top: 20px;
  opacity: 0;

  transition: all 100ms ease-in-out;

  border-radius: 7px;

  background-color: white;

  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.day-picker-wrapper-active {
  margin-top: 40px;
  padding: 5px 0;
  max-height: 500px;
  opacity: 1;
  z-index: 20 !important;
}

.suggestions {
  position: absolute;
  opacity: 0;
  z-index: 20 !important;

  border-radius: 7px;

  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 0;
  border: 1px solid rgba(153, 153, 153, 0);
  overflow-y: auto;
  min-width: 80px;
  padding-left: 0;
  background-color: white;
  transition: all 100ms ease-in-out;
  padding: 0;
  margin-top: 20px;

  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.suggestions.active {
  margin-top: 40px;
  padding: 5px 0;
  max-height: 500px;
  opacity: 1;
  z-index: 20 !important;
}

.suggestions-item {
  padding: 12px 12px;
  display: flex;
  cursor: pointer;
}

.suggestions-item-label {
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: 30px;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

.suggestions-item > svg {
  opacity: 0.7;
}

.suggestions-item-field {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 6px;
  opacity: 0.7;
}

.suggestions-item.active {
  background-color: rgba(0, 0, 0, 0.04);
}
