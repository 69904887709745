.speaker_settings_modal_container {
    display: flex;
    flex-direction: column;
    margin-top: 42px;
}

.speaker_setting_container {
    display: flex;
}

.speaker_setting_title {
    margin: 0;
    flex: 1;
}

.speaker_settings_modal_container .speaker_setting_container:nth-child(2) {
    margin: 12px 0;
}

#confirm_button {
    width: 75px;
    height: 32px;
    color: #ffffff;
    background-color: #2b8dff;
    font-family: 'Roboto';
    font-size: 14px;
    cursor: pointer;
    border: none;
    outline: none;
    font-weight: bolder;
    border-radius: 0;
    transition: all 0.2s ease-in-out;
    margin-top: 42px;
}

#confirm_button:hover {
    background-color: rgb(13, 126, 255);
    transition: all 0.2s ease-in-out;
}