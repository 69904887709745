.consumption_bar_container {
    width: 100%;
    height: 100%;
    position: relative;
}

.consumption_chunk {
    border-radius: 2px;
    height: 100%;
}

.consumption_bar_seperators_container {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 25%;
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.consumption_bar_seperator {
    height: 100%;
    width: 1px;
    background-color: #F2F2F2;
}