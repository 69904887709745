.dict_modal_container {
  position: relative;
  min-height: 0;
  -webkit-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  width: 100%;
  min-width: 320px;
  max-width: 425px;
  max-height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  background-color: #f2f2f2;
}

.dict_modal_content_wrapper {
  width: 100%;
  background-color: #f2f2f2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: auto;
  min-height: 0;
  -webkit-transform: translate3d(0px, 0px, 0px);
  -ms-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  overflow-x: hidden;
  padding: 50px 50px 50px 58px;
  transition: all 200ms ease-in-out;
}

.dicst_modal_main_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.selectedword_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.dicst_modal_main_wrapper .MuiInput-underline:before {
  border-bottom: 1px solid #c1c1c1 !important;
}
.dicst_modal_main_wrapper .MuiInputBase-input {
  padding: 3px 2px !important;
  color: #777777 !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: 'Roboto' !important;
}

.word_info_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.pronunciationa_wrapper::-webkit-scrollbar {
  display: flex !important;
  width: 6px;
}
/* Track */
.pronunciationa_wrapper::-webkit-scrollbar-track {
  background: #e2e2e2;
}

/* Handle */
.pronunciationa_wrapper::-webkit-scrollbar-thumb {
  background: #2b8dff;
}

/* Handle on hover */
.pronunciationa_wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.pronunciationa_wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% + 15px);
  height: 320px;
  align-items: flex-start;
  margin-bottom: 30px;
  overflow-y: auto;
  padding-right: 15px;
}

.pronunciation_main {
  border-bottom: 1px solid #c1c1c1;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 100%;
}
.pronunciation_main.error {
  border-bottom: 1px solid red;
}

.addpronunciation_btn {
  position: relative;
  padding: 0;
  margin: 8px 0 0 0;
  outline: none;
  border: none;
  background: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 24px;
  min-height: 24px;
  background-color: #2b8dfc;
  border-radius: 50%;
}

.addpronunciation_btn div:first-child {
  position: absolute;
  width: 35%;
  height: 2px;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.addpronunciation_btn div:last-child {
  position: absolute;
  width: 2px;
  height: 35%;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selectedword_wrappper {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: #000000;
}
.selectedword_wrappper .MuiSelect-icon {
  color: #c1c1c1 !important;
}

.selected_word {
  font-size: 36px;
  font-weight: 300;
  word-break: break-word;
  line-height: 1;
  /* height: 43px; */
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3px;
  margin-top: 3px;
}

.new_word_dict {
  color: #bc1c1c;
  font-size: 12px;
  font-weight: 300;
}
.new_word_dict.status {
  position: absolute;
  top: 50px;
}

.title_dict {
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  /* margin-bottom: 15px; */
}

.pronunciation_input {
  /* border-bottom: 1px solid #c1c1c1; */
  padding-bottom: 3px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: normal;
  color: #777777;
  max-width: calc(100% - 75px);
  letter-spacing: normal;
  margin-right: 20px;
  flex-shrink: 1;
  width: 100%;
  line-height: 1.1876em;
  width: 100%;
  /* min-width: 100%; */
  margin: 0;
}

.word_info_wrapper .MuiInputBase-input {
  font-size: 16px !important;
  color: #777777 !important;
}

.word_info_wrapper .MuiInput-underline:before {
  border-bottom: 1px solid #c1c1c1 !important;
}

/* .pronunciation_main:hover .delete_icon_wrapper {
  opacity: 1;
  pointer-events: all;
  transition:  all 200ms ease-in-out;
} */

.delete_icon_wrapper_absolute {
  /*   opacity: 0;
  pointer-events: none; */
  position: absolute;
  right: 100%;
  padding-right: 5px;
  color: #c1c1c1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms ease-in-out;
}

.delete_icon_wrapper_normal {
  color: #c1c1c1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: color 250ms ease-in-out;
}
.delete_icon_wrapper_normal:hover {
  color: #8a8a8a;
  transition: color 250ms ease-in-out;
}

.pronunciation_main .MuiFormControl-root {
  width: 120px !important;
}

@media only screen and (max-width: 600px) {
  .dict_modal_content_wrapper {
    padding: 25px 25px 25px 30px;
  }
}
