.bar {
  user-select: none;
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}

.bar__time {
  color: black;
  font-size: 16px;
}

.bar__progress {
  flex: 1;
  margin: 0;
  height: 6px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  /* transition: all 100ms ease-in-out; */
}

.time_string_popup {
  position: absolute;
  bottom: calc(100% + 4px);
  left: 50%;
  font-family: 'Roboto';
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 6px 12px;
  color: #000000;
  font-weight: bold;
  background-color: #2b8dff;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  user-select: none !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

  /* transform: translateX(-125%); */
}

.time_string_popup_triangle {
  width: 0px;
  height: 0px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-top: 5px solid rgb(43, 141, 255);
  position: absolute;
  /* left: 50%; */
  transform: translateX(-50%);
  top: 100%;
}

/*.bar__progress__knob {
  position: relative;
  height: 15px;
  width: 3px;
  background-color: #2b8dff;
}*/
