.modal-wrapper {
    z-index: 35;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0000001A;
}

.modal-container {
    padding: 33px 44px 38px 49px;
    width: 40%;
    max-width: 500px;
    background-color: #F2F2F2;
}

.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    margin: 0;
}

.header-button {
    height: 24px;
    width: 24px;
}