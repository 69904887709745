.replace_words_modal_wrapper .MuiInputBase-input {
  padding: 3px 2px !important;
  color: #333333 !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: 'Roboto' !important;
}

.replace_words_modal_wrapper .MuiInput-underline:before {
  border-bottom: 1px solid #e6e6e6 !important;
}
.replace_words_modal_wrapper .MuiInput-underline:after {
  border-bottom: 1px solid #2b8dff !important;
}

.replace_words_modal_wrapper .MuiInput-underline.Mui-focused:after {
  transform: scaleX(1) !important;
}

.replace_words_modal_wrapper {
  width: 100%;
  min-width: 300px;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Roboto';
  font-size: 16px;
}

.replace_words_modal_selected_phrase {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 26px;
  color: #333333 !important;
  font-style: italic;
  margin-bottom: 25px;
}

.replace_words_modal_wrapper .modal_buttons_wrapper {
  /* margin-top: 105px; */
}

.helper_text {
  position: absolute !important;
  top: 25px;
  margin: 0;
}

.entries_modal__editor_wrapper {
  /* border: 1px solid #939393;
  padding: 10px; */
  color: #333333 !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-family: 'Roboto' !important;
  min-height: 300px;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 2px;
  margin-bottom: 2em;
  box-shadow: inset 0px 1px 8px -3px #ababab;
  background: #f6f6f6;
}
.entries_modal__editor_wrapper [data-block='true'] {
  min-height: unset !important;
  margin-bottom: unset !important;
}
.entries_modal__editor_wrapper .public-DraftEditorPlaceholder-inner {
  opacity: 0.4 !important;
  position: absolute;
  /* top: -25px; */
  /* left: 2px; */
}
.entry_badge {
  font-family: 'Roboto Mono';
  font-size: 14px;
  background-color: #2b8dff;
  color: white;
  padding: 2px 4px;
  border-radius: 2px;
}

.entries_modal__editor__toolbar_wrapper {
  margin-bottom: 5px;
}
.entries_modal__editor__toolbar_wrapper > div {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  /* background-color: red; */
}
.entries_modal__editor__toolbar_wrapper button {
  background: none !important;
}
.entries_modal__editor__toolbar_wrapper button {
  background: none !important;
}
.entries_modal__editor__toolbar_wrapper button:hover svg {
  fill: #2b8dff !important;
}

.entries_modal__editor__toolbar_wrapper div:first-of-type div:first-of-type div:last-child {
  display: none !important;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}
